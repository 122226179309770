.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.rdt_Table {
  font-family: "Open Sans", sans-serif;
  position: relative;
}
.rdt_TableHead {
  background-color: rgb(23 23 23);
}

.horizontal-slider {
  width: 60%;
  height: 10px;
  border: 1px solid white;
  border-radius: 25px;
}
.example-track-1 {
  background-color: #d22326;
  height: 100%;
  border-radius: 25px;
}

.example-thumb {
  font-size: 12px;
  background-color: #282c34;
  color: white;
  cursor: pointer;
  top: 50%;
  width: 12px;
  height: 12px;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  border: 1px solid white;
  position: relative;
  transition: background-color 0.2s ease-in-out;
}
.example-thumb:hover {
  background-color: #d22326;
}

.handle.active {
  border: 5px solid white;
}

.bar {
  position: relative;
  background: #ff7200;
}

.bar.bar-1 {
  background: #a5a5a5;
}

.horizontal-slider .bar {
  top: 6px;
  height: 26px;
  border-radius: 16px;
  margin: 0 6px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Dropdown-option {
  color:white !important;
}
.Dropdown-option:hover {
  background-color: #282c34 !important;
  color:white !important;
}
.Dropdown-option.is-selected {
  color: #d22326 !important;
  background-color: #282c34 !important;
}